export const injectFavicon = (faviconUrl: string): void => {
  let link: HTMLLinkElement | null = document.querySelector("link[rel*='icon']")

  if (!link) {
    link = document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'icon'
    document.head.appendChild(link)
  }

  link.href = faviconUrl
}
