import React from 'react'
import { ReactComponent as LogoSvg } from './logo.svg'
import styled from 'styled-components'

const Logo: React.FC = () => {
  return <TaglineLogo />
}

const TaglineLogo = styled(LogoSvg)`
  width: 240px;
`

export default Logo
