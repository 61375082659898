import React from 'react'
import { ReactComponent as LogoSvg } from './logo.svg'
import styled from 'styled-components'

const Logo: React.FC = () => {
  return <CogiaLogo />
}

const CogiaLogo = styled(LogoSvg)`
  margin: 32px 0;
  width: 140px;
  border-radius: 12px;
  fill: #1c658e;
  @media screen and (max-width: 856px) {
    width: 52px;
  }
`

export default Logo
