import React from 'react'
import LogoJpg from './logo.jpg'
import styled from 'styled-components'

const Logo: React.FC = () => {
  return <AiSecureLogo />
}

const AiSecureLogo = styled.img.attrs({ src: LogoJpg })`
  margin: 32px 0;
  border-radius: 32px;
  width: 120px;
  @media screen and (max-width: 856px) {
    width: 52px;
  }
`

export default Logo
