import React, { FC } from 'react'
import { ModalProps, ModalView } from 'components/organisms/Modal'
import { useTranslation } from 'react-i18next'
import { brandName, brandTitle } from 'brand'

const About: FC<ModalProps> = ({ onClose }) => {
  const [t] = useTranslation()
  return (
    <ModalView title={`${t('common.about')} ${brandTitle}`} style={{ minWidth: '600px' }} onClose={onClose}>
      <iframe
        id="about"
        src={`https://socializer.one/${brandName}/in-app-pages/about`}
        width="100%"
        height="400px"
        title="About"
        frameBorder="0"
      />
    </ModalView>
  )
}

export default About
