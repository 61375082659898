import React, { useEffect } from 'react'
import { ConfirmDialogProvider } from 'components/organisms/ConfirmDialog'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AppThemeProvider } from 'theme'
import { UserContextProvider } from './contexts/UserContext'
import Router from './Router'
import { LocalizationProvider } from 'locales/i18n'
import { favicon } from 'brand'
import { injectFavicon } from 'utils/injectFavicon'

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnMount: 'always', refetchOnWindowFocus: false } },
})

function Root() {
  useEffect(() => {
    injectFavicon(favicon)
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <AppThemeProvider>
        <UserContextProvider>
          <ConfirmDialogProvider>
            <LocalizationProvider>
              <Router />
            </LocalizationProvider>
          </ConfirmDialogProvider>
        </UserContextProvider>
      </AppThemeProvider>
    </QueryClientProvider>
  )
}

export default Root
