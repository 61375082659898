import React from 'react'
import LogoJpg from './logo.jpg'
import styled from 'styled-components'

const Logo: React.FC = () => {
  return <AiSecureLogo />
}

const AiSecureLogo = styled.img.attrs({ src: LogoJpg })`
  width: 180px;
  border-radius: 32px;
`

export default Logo
