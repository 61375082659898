import { FC } from 'react'

let brandName: string
let brandTitle: string
let favicon: string
let LoginLogo: FC
let NavLogo: FC

switch (process.env.REACT_APP_BRAND) {
  case 'ai-secure':
    brandName = 'ai-secure'
    brandTitle = 'AI Secure'
    favicon = require('brand/assets/ai-secure/favicon.svg').default
    LoginLogo = require('brand/assets/ai-secure/LoginLogo.tsx').default
    NavLogo = require('brand/assets/ai-secure/NavLogo.tsx').default
    break
  case 'tagline':
    brandName = 'tagline'
    brandTitle = 'Tagline'
    favicon = require('brand/assets/tagline/favicon.svg').default
    LoginLogo = require('brand/assets/tagline/LoginLogo.tsx').default
    NavLogo = require('brand/assets/tagline/NavLogo.tsx').default
    break
  case 'cogia':
  default:
    brandName = 'cogia'
    brandTitle = 'Cogia'
    favicon = require('brand/assets/cogia/favicon.svg').default
    LoginLogo = require('brand/assets/cogia/LoginLogo.tsx').default
    NavLogo = require('brand/assets/cogia/NavLogo.tsx').default
    break
}

export { NavLogo, LoginLogo, favicon, brandName, brandTitle }
