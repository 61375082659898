import React from 'react'
import { ReactComponent as LogoSvg } from './logo.svg'
import styled from 'styled-components'

const Logo: React.FC = () => {
  return <CogiaLogo />
}

const CogiaLogo = styled(LogoSvg)`
  width: 240px;
  border-radius: 12px;
`

export default Logo
