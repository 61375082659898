import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ModalProps, ModalView } from 'components/organisms/Modal'
import Button from 'components/molecules/Button'
import { createBroadcastFn, deleteFile, getBroadcastsChannelsFn, getUsersFn, uploadFile } from 'api'
import { Controller, useForm } from 'react-hook-form'
import { Switch } from 'components/molecules/Switch'
import { BlackBoldParagraph, GreyParagraph2 } from 'components/atoms/Paragraph'
import ReactSelect from 'components/molecules/ReactSelect'
import { toast } from 'react-toastify'
import { TextArea } from 'components/atoms/Input'
import { useTranslation } from 'react-i18next'
import { sortByAlphabet } from 'utils/array'
import { SelectOption, UploadedFile } from '@types'
import { useUserContext } from 'contexts/UserContext'

import { ReactComponent as SpinnerSvg } from 'components/assets/spinner.svg'

interface BroadcastForm {
  text: string
  users: SelectOption[] | string
  channel: string
  files: any[]
}

const NewBroadcast: FC<ModalProps> = ({ onClose }) => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const { state } = useUserContext()
  const { i_realm } = state?.realm
  const [rawMode, setRawMode] = useState(false)
  const [files, setFiles] = useState<UploadedFile[]>([])
  const [uploading, setUploading] = useState(false)

  const { data: usersOptions, isLoading } = useQuery(['users', i_realm], getUsersFn, {
    select: (data) => {
      return data
        .filter(({ last_login }) => !!last_login)
        .map(({ name }) => ({ value: name, label: name }))
        .sort((a, b) => sortByAlphabet(a.value, b.value))
    },
  })

  const { data: channels } = useQuery(['broadcast-channels', i_realm], getBroadcastsChannelsFn, {
    select: (data) => {
      return data.map(({ i_broadcast_channel, name }) => ({ value: i_broadcast_channel, label: name }))
    },
  })

  const {
    control,
    formState: { isValid, isSubmitted },
    handleSubmit,
  } = useForm<BroadcastForm>()

  const { mutate } = useMutation(createBroadcastFn, {
    onError: () => {
      toast.error(t('modal.newBroadcast.createError'))
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['broadcasts'])
      toast.success(t('modal.newBroadcast.successSend'))
      onClose()
    },
  })

  const handleChange = (onChange: any) => (options: { value: string; option: string }[]) => {
    onChange(options)
  }

  const handleDeleteFile = async (key: string) => {
    setUploading(true)
    try {
      await deleteFile(key)
      setFiles(files.filter((file) => file.object.key !== key))
    } catch (e) {
      toast.error(t('error.default'))
    } finally {
      setUploading(false)
    }
  }
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const userFiles = Array.from(e.target.files || [])
    if (userFiles.length === 0) {
      return
    }
    setUploading(true)
    try {
      const uploadedFiles = await uploadFile(userFiles)
      setFiles([...files, ...uploadedFiles])
    } catch (e) {
      toast.error(t('error.default'))
    } finally {
      setUploading(false)
    }
  }

  const onSubmit = ({ text, users, channel }: BroadcastForm) => {
    let user_ids
    let isValid

    if (rawMode) {
      user_ids = (users as string).split('\n').map((userId) => userId.trim())

      const invalidUsers = user_ids.filter(
        (userId) => !(usersOptions as SelectOption[]).some(({ value }) => value === userId)
      )

      isValid = !invalidUsers.length
      if (!isValid) {
        toast.warning(t('toast.nonValidUsers', { users: invalidUsers.join(', ') }))
      }
    } else {
      user_ids = (users as SelectOption[]).map(({ value }) => value)
      isValid = true
    }

    if (isValid) {
      mutate({
        text,
        user_ids,
        channel,
        i_realm,
        files,
      })
    }
  }

  return (
    <ModalView title={t('modal.newBroadcast.title')} onClose={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <BlackBoldParagraph text={t('modal.newBroadcast.rawMode')} />
          <Switch checked={rawMode} onToggle={(v) => setRawMode(v!)} />
        </div>
        <Field>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <GreyParagraph2
              style={{ marginLeft: '15px', marginBottom: '5px' }}
              size="11"
              text={t('common.recipients')}
            />
            <Controller
              name="users"
              rules={{ required: true }}
              control={control}
              render={({ field: { ref, onChange, value, ...props } }) => {
                if (rawMode) {
                  return <StyledTextArea rows={6} onChange={onChange} {...props} />
                }
                return (
                  <ReactSelect
                    value={value}
                    placeholder={t('placeholder.chooseRecipients')}
                    onChange={handleChange(onChange)}
                    options={usersOptions}
                    isLoading={isLoading}
                    isMulti
                    {...props}
                  />
                )
              }}
            />
          </div>
        </Field>

        <Field>
          <Controller
            name="text"
            defaultValue=""
            rules={{ required: true }}
            control={control}
            render={({ field: { ref, ...props } }) => (
              <div>
                <GreyParagraph2 style={{ marginLeft: '15px' }} size="11" text={t('common.text')} />
                <StyledTextArea rows={4} {...props} />
              </div>
            )}
          />
        </Field>

        <Field>
          <Controller
            name="files"
            defaultValue={[]}
            rules={{ required: false }}
            control={control}
            render={({ field: { ref, ...props } }) => (
              <div>
                <GreyParagraph2 style={{ marginLeft: '15px' }} size="11" text={t('common.files')} />
                <input
                  type="file"
                  multiple={false}
                  onChange={handleFileUpload}
                  value={''}
                  style={{ visibility: uploading ? 'hidden' : 'visible' }}
                />
                {uploading && <SpinnerSvg />}
                {!uploading &&
                  files.map((file) => (
                    <div key={file.filename} style={{ display: 'flex-inline' }}>
                      <GreyParagraph2 size="11" text={file.filename} />
                      <button onClick={() => handleDeleteFile(file.object.key)} className="x-button">
                        Delete
                      </button>
                    </div>
                  ))}
              </div>
            )}
          />
        </Field>
        <Field>
          <Controller
            name="channel"
            rules={{ required: true }}
            control={control}
            render={({ field: { ref, onChange, value, ...props } }) => {
              return (
                <ReactSelect
                  value={channels?.filter((g) => g.value === +value)}
                  onChange={({ value }) => {
                    onChange(value)
                  }}
                  options={channels}
                  placeholder={t('placeholder.chooseChannel')}
                  {...props}
                />
              )
            }}
          />
        </Field>

        <Button appearance="blue" type="submit" disabled={!isValid || isSubmitted} style={{ placeSelf: 'flex-end' }}>
          {t('common.send')}
        </Button>
      </Form>
    </ModalView>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`
const Field = styled.div`
  display: flex;
  margin-bottom: 16px;

  & > * {
    flex: 1;
  }
`

const StyledTextArea = styled(TextArea)`
  width: 100%;
`

export default NewBroadcast
