import React from 'react'

import { Center } from 'components/atoms/Center'
import styled from 'styled-components'
import { NavLogo } from 'brand'

export default function NavHeader() {
  return (
    <Container>
      <Center>
        <NavLogo />
      </Center>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr auto;
  margin-bottom: 16px;
  position: sticky;
  top: 0;
  background: #fff;
`
